import React from 'react';
import styled from 'styled-components';

import { Box } from '@core';
import { SectionContainerSC as SectionContainer, Text } from '@components';

const BoxWithGap = styled(Box)`
  gap: 24px;
`;

const StatsCard = ({ value, description }) => (
  <Box width={{ _: 360, lg: 588 }} p={32}>
    <Text.Heading2 fontSize={{ _: 40, lg: 50 }} color="danger" textAlign="center">
      {value}
    </Text.Heading2>
    <Text.Subheading2 mt={24} textAlign="center">
      {description}
    </Text.Subheading2>
  </Box>
);

const CybercrimeStatsSection = ({ title, description, stats }) => {
  return (
    <SectionContainer
      data-section-id="cybercrime-stats-section"
      title={title}
      description={description}
      containerProps={{ pb: { _: 40, md: 80 } }}
    >
      <BoxWithGap
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection={{ _: 'column', lg: 'row' }}
      >
        <StatsCard value={stats.moneyLost.value} description={stats.moneyLost.label} />
        <StatsCard value={stats.victims.value} description={stats.victims.label} />
      </BoxWithGap>
    </SectionContainer>
  );
};

export default CybercrimeStatsSection;
